<template>
  <div>
    <loading v-if="displayLoading"></loading>
    <div v-if="staff" class="container mt-5">
      <div v-if="!success" id="step1">
        <div class="text-center mt-2">
          <h1 class="h5 font-weight-bold">業務人員綁定</h1>
        </div>
        <div class="p-3">
          <b-card class="mt-3" :img-src="getAvatar" img-alt="Image" img-top>
            <a
              href="#"
              class="
                list-item-heading
                mb-1
                truncate
                text-primary
                font-weight-bold
              "
            >
              {{ staff.name }}
            </a>
            <p class="mb-2 text-muted text-small">
              {{ staff.service_unit }}{{ staff.job_level }}
            </p>
            <button type="button" class="btn btn-xs btn-outline-primary">
              {{ staff.mobile_phone }}
            </button>
          </b-card>
        </div>
        <div class="text-center mt-3">
          <div class="h5">
            將
            <span class="text-primary px-2">{{ staff.name }}</span>
            設定為您的業務？
          </div>
        </div>

        <div class="d-flex justify-content-between mt-4">
          <b-button
            size="lg"
            variant="outline-dark mr-3 w-100"
            @click="reject"
            :disabled="buttonLoading"
            >否</b-button
          >
          <b-button
            size="lg"
            variant="primary w-100"
            @click="accept"
            :disabled="buttonLoading"
            >是</b-button
          >
        </div>
      </div>

      <div v-else id="step2" class="p-3">
        <b-card class="mt-3" :img-src="getAvatar" img-alt="Image" img-top>
          <div class="text-center font-weight-bolder">
            <h3>{{ staff.name }}</h3>
            <h3>{{ staff.employee_code }}</h3>
            <p class="text-muted text-small">
              {{ staff.service_unit }}{{ staff.job_level }}
            </p>
          </div>
        </b-card>

        <div v-if="staff" class="text-center mt-3">
          <h5>帳號綁定成功</h5>
        </div>
        <div class="text-center">
          <b-button @click="cancel" variant="success" class="mb-1 mt-3">
            回官方帳號
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import liff from "@line/liff";
import LiffChecker from "@/utils/LiffChecker";
import axios from "axios";

export default {
  data() {
    return {
      displayLoading: true,
      lineData: null,
      staff: null,
      buttonLoading: false,
      success: false,
    };
  },
  mounted() {
    this.init();
    //this.displayLoading = false;
  },
  computed: {
    getAvatar() {
      return this.staff?.avatar_url ?? "https://placekitten.com/300/300";
    },
  },
  methods: {
    async init() {
      const checker = new LiffChecker(this.$route.params.org_code);
      this.lineData = await checker.getLineData();

      let sid = this.$route.query.sid;
      if (!sid) {
        alert("網址錯誤，請洽詢業務索取正確網址");
        // FIXME: 改成導向錯誤頁面
        await this.$router.push("/");
        return;
      }

      let response = await axios.get(
        process.env.VUE_APP_API_BASE_URL + "/general/staffs/" + sid
      );

      if (response.data.data) {
        this.staff = response.data.data;
        this.displayLoading = false;
      } else {
        alert("讀取資料錯誤");
        liff.closeWindow();
      }

      if (this.$route.query.skip_confirm) {
        this.accept();
      }
    },
    reject() {
      liff.closeWindow();
    },
    async accept() {
      let _this = this;
      _this.buttonLoading = true;
      const url =
        process.env.VUE_APP_API_BASE_URL +
        "/" +
        _this.$route.params.org_code +
        "/liff/bind_staff";

      await axios
        .post(url, {
          line_id: this.lineData.profile.userId,
          line_access_token: this.lineData.accessToken,
          sid: this.staff.id,
        })
        .then((response) => {
          if (response.data.status === "success") {
            this.success = true;

            // alert("綁定成功");
            // liff.closeWindow();
            return;
          }
          if (response.data.status === "failed") {
            alert(response.data.message);
          }
          _this.buttonLoading = false;
        })
        .catch((error) => {
          console.log(error);
          _this.buttonLoading = false;
        });
    },
    cancel() {
      liff.closeWindow();
    },
  },
};
</script>
