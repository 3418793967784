<template>
  <div>
    <div v-if="displayLoading">
      <div class="loading"></div>
      <div class="loading-overlay"></div>
    </div>
      <img v-if="image" :src="image" class="w-100" />
      <div class="page">
        <div class="page__title">{{ getTitle }}</div>
        <div class="page__desc" v-html="getFormTitle"></div>

        <div class="form">
          <div
            v-for="(field, key) in bindFields"
            v-bind:key="key"
            class="form-group"
          >
            <div v-if="field.type === 'mobile_phone'">
              <label class="field__label">{{ field.title }}</label>
              <input
                class="field__input"
                type="tel"
                :placeholder="field.placeholder"
                v-model="bindFieldsInput[field.matched_key]"
                :disabled="fetchError"
                maxlength="10"
              />
              <small v-if="field.memo" class="field__desc">{{
                field.memo
              }}</small>
              <div
                v-if="v$.bindFieldsInput.mobile_phone.$error"
                class="form__error-msg"
              >
                {{ getValidateText(field) }}
              </div>
            </div>
            <div v-else-if="field.type === 'TWIDNumber'">
              <label class="field__label">{{ field.title }}</label>
              <input
                class="field__input"
                type="text"
                :placeholder="field.placeholder"
                v-model="bindFieldsInput[field.matched_key]"
                :disabled="fetchError"
                maxlength="10"
              />
              <small v-if="field.memo" class="field__desc">{{
                field.memo
              }}</small>
              <div
                v-if="v$.bindFieldsInput.TWIDNumber.$error"
                class="form__error-msg"
              >
                {{ getValidateText(field) }}
              </div>
            </div>
            <div v-else>
              <label class="field__label">{{ field.title }}</label>
              <input
                class="field__input"
                type="text"
                :placeholder="field.placeholder"
                v-model="bindFieldsInput[field.matched_key]"
                :disabled="fetchError"
              />
              <small v-if="field.memo" class="field__desc">{{
                field.memo
              }}</small>
              <div
                v-if="v$.bindFieldsInput[field.matched_key].$error"
                class="form__error-msg"
              >
                {{ getValidateText(field) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page__button">
        <SharedButton
          class="s-btn-outline-primary"
          @click="exit"
          :disabled="buttonLoading"
          >{{ getExitActionText }}</SharedButton
        >
        <SharedButton
          class="s-btn-bg-primary"
          @click="checkSubmit"
          :disabled="buttonLoading"
          >{{ getSubmitActionText }}</SharedButton
        >
      </div>
  </div>
</template>

<script>
import liff from "@line/liff";
import LiffChecker from "@/utils/LiffChecker";
import axios from "axios";
import SharedButton from "@/components/Page/Liff/Shared/Button";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { verifyIdNumber } from "@/utils/checkIdNumber";
// const MODULE_CODE = "sales_customer_search";

export default {
  components: {
    SharedButton
  },
  setup: () => ({ v$: useVuelidate({ $lazy: true }) }),
  data() {
    return {
      displayLoading: true,
      lineData: null,
      staff: {
        name: null
      },
      buttonLoading: false,
      success: false,
      moduleConfig: {},
      bindFields: [],
      bindFieldsInput: {},
      providerCheckConfig: {},
      fetchError: false
    };
  },
   validations() {
    let bindFieldsInput = {};

    for (let i = 0; i < this.bindFields.length; i++) {
      let field = this.bindFields[i];
      // 特殊規則 手機
      if (field.type === "mobile_phone") {
        bindFieldsInput.mobile_phone = this.isFieldRequired("mobile_phone")
          ? {
              required,
              mobile: (val) => {
                return /09[0-9]{8}/.test(val) && val.length === 10;
              },
            }
          : {
              mobile: (val) => {
                if (val) {
                  return /09[0-9]{8}/.test(val) && val.length === 10;
                } else {
                  return true
                }
              },
            };
      }
      // 身分證字號
      if (field.type === "TWIDNumber") {
        bindFieldsInput.TWIDNumber = this.isFieldRequired("TWIDNumber")
          ? {
              required,
              id_number: (val) => {
                return verifyIdNumber(val);
              },
            }
          : {
              id_number: (val) => {
                if (val) {
                  return verifyIdNumber(val);
                } else {
                  return true
                }
              },
            };
        continue;
      }

      // 一般欄位規則
      bindFieldsInput[field.field_key] = field.required === true ? { required } : {};
    }

    return {
      bindFieldsInput,
    };
  },
  mounted() {
    this.init();
    if(this.$route.query.error_message) {
      this.$swal.fire({
        type: "error",
        title: "錯誤",
        text: this.$route.query.error_message,
      })
    }
    //this.displayLoading = false;
  },
  watch: {
    bindFieldsInput: {
      handler: function (val) {
        if (val['TWIDNumber']) {
          this.bindFieldsInput.TWIDNumber = val['TWIDNumber'].toUpperCase();
        }
      },
      deep: true,
    },
  },
  computed: {
    getAvatar() {
      if (this.moduleConfig?.staff_profile_display) {
        let avatarUrlConfig = this.moduleConfig?.staff_profile_display.find(
          (value) => value.key === "avatar_url"
        );
        if (avatarUrlConfig.is_display) {
          return (
            this.staff?.avatar_url ??
            "https://storage.googleapis.com/waltily-prod/default_pic/default_avatar.png"
          );
        }
      }
      return null;
    },
    getTitle() {
      return this.providerCheckConfig?.page_title ?? "帳號登錄";
    },
    getFormTitle() {
      return this.providerCheckConfig.search_page?.form_title ?? "請收取簡訊，填入驗證碼";
    },
    staffProfileDisplay() {
      let mappings = this.moduleConfig?.staff_profile_display ?? [];

      return mappings
        .filter((mapping) => {
          return mapping.key !== "avatar_url" && mapping.is_display;
        })
        .sort((a, b) => {
          return a.order - b.order;
        });
    },
    getExitActionText() {
      return this.providerCheckConfig.search_page?.cancel_button_text ?? "取消";
    },
    getSubmitActionText() {
      return this.providerCheckConfig.search_page?.submit_button_text ?? "送出";
    },
    image() {
      return this.providerCheckConfig.page_kv_image ?? "";
    }
  },
  methods: {
    getValidateText(field) {
      if (field.type == 'mobile_phone') {
        return field.validate_text ?? '手機號碼不符合規則';
      } else {
        return field.validate_text ?? '輸入的資料有誤或未填寫，請確認';
      }
    },
    isFieldRequired(key) {
      return this.bindFields.some(
        (field) => field.type === key && field.required == true
      );
    },
    async init() {
      if (this.$route.query.inline == "1" && !liff.isInClient()) {
        let query = this.$route.query;
        query.inline = 0;

        this.$router.push({
          name: "LiffSalesOpenInLine",
          params: this.$route.params,
          query: query,
        });
        return;
      }

      const checker = new LiffChecker(this.$route.params.org_code, {
        notLogin: true,
      });
      this.lineData = await checker.getLineData();

      // Fetch module config
      await this.fetchModuleConfig();

      this.displayLoading = false;
    },
    async fetchModuleConfig() {
      try {
        const { data } = await axios.get(
          process.env.VUE_APP_API_BASE_URL +
            `/${this.$route.params.org_code}/liff/sales/module-config`
        );
        this.moduleConfig = data.data;
        const source = await axios.get(
          process.env.VUE_APP_API_BASE_URL +
            `/${this.$route.params.org_code}/liff/modules/sales_customer_search`
        );
        this.providerCheckConfig = source.data.data.module_config
        this.setForm()
      } catch (error) {
        this.fetchError = true
        let errorMessage = error.response?.data?.message ?? null;
        this.$swal("獲取設定出錯", errorMessage, "error");
      }
    },
    setForm() {
      console.log(this.providerCheckConfig)
      if (this.providerCheckConfig?.search_page?.form_field) {
        this.bindFields = this.providerCheckConfig?.search_page?.form_field;
      } else {
        this.bindFields = [
          {
            display_name: "員工編號",
            field_key: "employee_code",
          },
        ];
      }
    },
    async checkExtendStaff() {
      let { data } = await axios.get(
          process.env.VUE_APP_API_BASE_URL +
            `/${this.$route.params.org_code}/liff/sales/check-extend-staff`,
          {
            params: this.bindFieldsInput,
          }
        )
      if (data.active_url) {
        window.location.href = data.active_url
      }
      // this.displayLoading = false
    },
    async checkSubmit() {
      try {
        this.buttonLoading = true;
        this.displayLoading = true
        const result = await this.v$.$validate();
        if (!result) {
          this.buttonLoading = false;
          this.displayLoading = false
          return;
        }
        await this.checkExtendStaff()
        // this.displayLoading = false

      } catch (error) {
        if (error !== "error") {
          if (error.response?.status === 404) {
            this.$swal.fire({
              type: "warning",
              title: "找不到業務資訊",
            });
          } else {
            this.$swal.fire({
              type: "error",
              title: "錯誤",
              text: error.response?.data?.message,
            });
          }
        }
        this.displayLoading = false
      }
      this.buttonLoading = false;
    },
    async exit() {
      if (
        this.moduleConfig.result_page?.exit_close === false &&
        this.moduleConfig.result_page?.exit_url
      ) {
        window.location.href = this.moduleConfig.result_page?.exit_url;
      } else {
        liff.closeWindow();
        window.close();
      }
    },
    profileClass(style) {
      switch (style) {
        case "title":
          return "list-item-heading truncate text-primary font-weight-bold";
        case "text":
          return "text-muted text-small";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .card {
  width: 250px;
  margin: auto;
}

.loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0,0,0,.2 );
  border-radius: 50%;
  border-top-color: rgba(0,0,0,.4 );
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  left: calc(50%);
  top: calc(50%);
  position: fixed;
  z-index: 9999;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9998;
  background-color: background-color;
}

.field {
  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
  &__label {
    font-weight: 400;
    font-size: 14px;
    color: var(--liff-primary_text_color);
    margin-bottom: 8px;
    display: block;
  }

  &__desc {
    color: var(--liff-secondary_text_color);
    font-size: 12px;
    line-height: 1;
    margin-top: 4px;
  }

  &__input {
    font-weight: 500;
    font-size: 16px;
    color: var(--liff-primary_text_color);
    border: none;
    border-bottom: 1px solid #e5e5ea;
    background: transparent;
    padding-bottom: 8px;
    width: 100%;
    border: 1px solid #e5e5ea;
    border-radius: 5px;
    padding: 8px 12px;
    &::placeholder {
      color: #b7b7b7;
    }
    &:focus {
      border: 1px solid var(--liff-primary_text_color);
    }
    &.invalid {
      border: 1px solid #fe0000;
    }

    &:disabled {
      color: var(--s-gray);
      background-color: var(--s-gray-light);
    }
  }

  &--required {
    &:after {
      content: "*";
      position: relative;
      font-family: "PingFang TC";
      color: var(--s-primary);
      margin-left: 2px;
    }
  }

  &__select-group {
    display: flex;
    > * {
      width: 100%;
      &:not(:last-of-type) {
        margin-right: 8px;
      }
    }
    &.invalid {
      border-bottom: 1px solid #fe0000;
    }
  }
}

.form-group {
    margin-top: 8px;
}

.form {
  padding: 0 5px;
  &__error-msg {
    color: #fe0000;
    font-size: 12px;
    margin-bottom: 8px;
  }
}
</style>
