<template>
  <div>
    <loading v-if="displayLoading"></loading>
    <div class="container mt-5">
      <div class="p-3">
        <b-card>
          <div class="text-center mt-2">
            <h1 class="h5 font-weight-bold">點選下方按鈕綁定業務</h1>
          </div>
          <div class="d-flex justify-content-between" style="margin-top: 50px;">
            <SharedButton class="s-btn-bg-primary" @click="() => goto()">前往綁定</SharedButton>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import LiffChecker from "@/utils/LiffChecker";
import SharedButton from "@/components/Page/Liff/Shared/Button";

export default {
  components: {
    SharedButton
  },
  data() {
    return {
      displayLoading: true,
      url: null,
    };
  },
  mounted() {
    this.init();
    //this.displayLoading = false;
  },
  methods: {
    async init() {
      const checker = new LiffChecker(this.$route.params.org_code);
      let initData = await checker.getInitData();

      this.url = `https://liff.line.me/${initData.liff_id}/sales/customer_bind_staff/${window.location.search}`;

      this.displayLoading = false;
    },
    goto() {
      window.location.href = this.url;
    }

  },
};
</script>
