<template>
  <div>
    <loading v-if="showLoading"></loading>
    <div class="container mt-5">
      <div class="text-center mt-3">
        <h5>{{ message }}</h5>
      </div>
      <div class="text-center">
        <b-button @click="cancel" variant="success" class="mb-1 mt-3">
          回官方帳號
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import liff from "@line/liff";
import LiffChecker from "@/utils/LiffChecker";
import axios from "axios";

export default {
  data() {
    return {
      showLoading: true,
      message: "送件通知已送出",
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      let orgCode = this.$route.params.org_code;
      const checker = new LiffChecker(orgCode);
      this.checker = checker;
      this.lineData = await checker.getLineData();

      let url = process.env.VUE_APP_API_BASE_URL + `/${orgCode}/liff/sales/customer-patch-upload`;
      try {
        await axios.post(url, {
          trigger: "customer",
          line_id: this.lineData.profile.userId,
          line_access_token: this.lineData.accessToken,
        });
      } catch (error) {
        console.error(error);
        if (error.response.data.message === "尚未綁定業務") {
          this.message = "您無綁定的業務專員";
        } else {
          this.message = error.response.data.message;
        }
      }
      this.showLoading = false;
    },
    cancel() {
      liff.closeWindow();
    },
  }
}
</script>
