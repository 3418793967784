<template>
  <div>
    <loading v-if="displayLoading"></loading>

    <template v-if="currentStep === 'check'">
      <div class="page">
        <div class="page__title">{{ getConfirmTitle }}</div>
        <div class="page__desc">請輸入業務資訊</div>

        <div class="form">
          <div
            v-for="(field, key) in bindFields"
            v-bind:key="key"
            class="form-group"
          >
            <label class="field__label">{{ field.display_name }}</label>
            <input
              class="field__input"
              type="text"
              v-model="bindFieldsInput[field.field_key]"
            />
          </div>
        </div>
      </div>

      <div class="page__button">
        <SharedButton
          class="s-btn-outline-primary"
          @click="checkCancel"
          :disabled="buttonLoading"
          >取消</SharedButton
        >
        <SharedButton
          class="s-btn-bg-primary"
          @click="checkSubmit"
          :disabled="buttonLoading"
          >送出</SharedButton
        >
      </div>
    </template>

    <template v-if="currentStep === 'confirm'">
      <div class="page">
        <div class="page__title">{{ getConfirmTitle }}</div>
        <div class="page__desc">
          將
          <span class="text-primary px-2">{{ staff.name }}</span>
          設定為您的業務？
        </div>
        <b-card class="mt-3" :img-src="getAvatar" img-alt="Image" img-top>
          <div
            v-for="(profile, key) in staffProfileDisplay"
            v-bind:key="key"
            :alt="profile.display_name"
          >
            <button
              v-if="profile.style === 'badge'"
              type="button"
              class="btn btn-xs btn-outline-primary"
            >
              {{ staff[profile.key] }}
            </button>
            <p v-else class="mb-2" :class="profileClass(profile.style)">
              {{ staff[profile.key] }}
            </p>
          </div>
        </b-card>
      </div>
      <div class="page__button">
        <SharedButton
          class="s-btn-outline-primary"
          @click="confirmCancel"
          :disabled="buttonLoading"
          >否</SharedButton
        >
        <SharedButton
          class="s-btn-bg-primary"
          @click="accept"
          :disabled="buttonLoading || disabledAcceptButton"
          >是</SharedButton
        >
      </div>
    </template>

    <template v-if="currentStep === 'result'">
      <div class="page">
        <div class="page__title">{{ getConfirmTitle }}</div>
        <div class="page__desc">
          {{ getResultSuccessMessage }}
        </div>
        <b-card class="mt-3" :img-src="getAvatar" img-alt="Image" img-top>
          <div
            v-for="(profile, key) in staffProfileDisplay"
            v-bind:key="key"
            :alt="profile.display_name"
          >
            <button
              v-if="profile.style === 'badge'"
              type="button"
              class="btn btn-xs btn-outline-primary"
            >
              {{ staff[profile.key] }}
            </button>
            <p v-else class="mb-2" :class="profileClass(profile.style)">
              {{ staff[profile.key] }}
            </p>
          </div>
        </b-card>
      </div>
      <div class="page__button">
        <SharedButton class="s-btn-bg-primary" @click="exit">{{
          getExitActionText
        }}</SharedButton>
      </div>
    </template>
  </div>
</template>

<script>
import liff from "@line/liff";
import LiffChecker from "@/utils/LiffChecker";
import axios from "axios";
import SharedButton from "@/components/Page/Liff/Shared/Button";

const MODULE_CODE = "confirm_page";

export default {
  components: {
    SharedButton
  },
  data() {
    return {
      displayLoading: true,
      currentStep: "confirm",
      lineData: null,
      staff: {
        name: null
      },
      btype: null,
      buttonLoading: false,
      success: false,
      moduleConfig: {},
      bindFields: [],
      bindFieldsInput: {},
      isQrcode: false,
      disabledAcceptButton:false
    };
  },
  mounted() {
    this.init();
    //this.displayLoading = false;
  },
  computed: {
    getAvatar() {
      if (this.moduleConfig?.staff_profile_display) {
        let avatarUrlConfig = this.moduleConfig?.staff_profile_display.find(
          (value) => value.key === "avatar_url"
        );
        if (avatarUrlConfig.is_display) {
          return (
            this.staff?.avatar_url ??
            "https://storage.googleapis.com/waltily-prod/default_pic/default_avatar.png"
          );
        }
      }
      return null;
    },
    getConfirmTitle() {
      return this.moduleConfig.confirm_page?.page_title ?? "綁定業務";
    },
    getCheckTitle() {
      return this.moduleConfig.check_page?.page_title ?? "綁定業務";
    },
    getCheckSubTitle() {
      return this.moduleConfig.check_page?.page_sub_title ?? "請輸入業務資訊";
    },
    getResultTitle() {
      return this.moduleConfig.result_page?.page_title ?? null;
    },
    getResultSuccessMessage() {
      return this.moduleConfig.result_page?.success_message ?? "成功綁定！";
    },
    staffProfileDisplay() {
      let mappings = this.moduleConfig?.staff_profile_display ?? [];

      return mappings
        .filter((mapping) => {
          return mapping.key !== "avatar_url" && mapping.is_display;
        })
        .sort((a, b) => {
          return a.order - b.order;
        });
    },
    getExitActionText() {
      return this.moduleConfig.result_page?.exit_text ?? "回官方帳號";
    },
  },
  methods: {
    getModuleConfig(key) {
      return this.$store.getters["liffModule/getConfig"](MODULE_CODE, key);
    },
    async init() {
      if (this.$route.query.inline == "1" && !liff.isInClient()) {
        let query = this.$route.query;
        query.inline = 0;

        this.$router.push({
          name: "LiffSalesOpenInLine",
          params: this.$route.params,
          query: query,
        });
        return;
      }

      const checker = new LiffChecker(this.$route.params.org_code, {
        notLogin: true,
      });
      this.lineData = await checker.getLineData();

      // Fetch module config
      await this.fetchModuleConfig();

      let sid = this.$route.query.sid;
      this.btype = this.$route.query.btype ?? null;

      // 如果沒有帶入 sid ，則直接先進 check step
      if (!sid) {
        this.goCheckStep();
      } else {
        try {
          await this.fetchStaff(sid);
          this.currentStep = "confirm";
          this.isQrcode = true;

          if (this.moduleConfig.confirm_page.show_this_page === false) {
            await this.accept();
            this.currentStep = "result";
          }
        } catch (error) {
          this.$swal("找不到業務資訊", "", "warning");
          this.goCheckStep();
        }
      }
      this.displayLoading = false;
    },
    goCheckStep() {
      this.currentStep = "check";
      this.isQrcode = false;
      if (this.moduleConfig.check_page.bind_field) {
        this.bindFields = this.moduleConfig.check_page.bind_field;
      } else {
        this.bindFields = [
          {
            display_name: "員工編號",
            field_key: "employee_code",
          },
        ];
      }
    },
    async fetchModuleConfig() {
      const { data } = await axios.get(
        process.env.VUE_APP_API_BASE_URL +
          `/${this.$route.params.org_code}/liff/sales/module-config`
      );
      this.moduleConfig = data.data;
    },
    async fetchStaff(sid) {
      let response = await axios.get(
        process.env.VUE_APP_API_BASE_URL +
          `/${this.$route.params.org_code}/liff/sales/get-staff?id=${sid}`
      );

      if (response.data.data) {
        this.staff = response.data.data;
        // this.displayLoading = false;
        if (!this.staff.is_enabled && !response.data.module_config?.is_enabled) {
          if (response.data.module_config?.message.action === 'sales_customer_search') {
            this.$router.push({
                name: "LiffSalesStaffSearch",
                query: {
                  error_message: response.data.module_config?.message.message
                }
              });
          } else {
            this.disabledAcceptButton = true
          }
        }
      } else {
        throw "Not found";
      }
    },
    async checkSubmit() {
      try {
        this.buttonLoading = true;

        this.bindFields.forEach((field) => {
          console.log(field);

          if (!this.bindFieldsInput[field.field_key]) {
            this.$swal.fire({
              type: "error",
              title: `請輸入${field.display_name}`,
            });
            this.buttonLoading = false;
            throw "error";
          }
        });

        let { data } = await axios.get(
          process.env.VUE_APP_API_BASE_URL +
            `/${this.$route.params.org_code}/liff/sales/check-staff`,
          {
            params: this.bindFieldsInput,
          }
        );
        this.staff = data.data;
        this.currentStep = "confirm";
        if (this.moduleConfig.confirm_page.show_this_page === false) {
          this.accept();
        }
      } catch (error) {
        if (error !== "error") {
          if (error.response?.status === 404) {
            this.$swal.fire({
              type: "warning",
              title: "找不到業務資訊",
            });
          } else {
            this.$swal.fire({
              type: "error",
              title: "錯誤",
            });
          }
        }
      }
      this.buttonLoading = false;
    },
    checkCancel() {
      this.staff = {
        name: null
      };
      this.bindFieldsInput = {};
    },
    confirmCancel() {
      // 移除 bytpe 參數
      let query = { ...this.$route.query };
      delete query.btype;

      this.$router.replace({
        query: query
      });
      this.btype = null

      this.bindFieldsInput = {};
      this.goCheckStep();
    },
    async accept() {
      let _this = this;
      _this.buttonLoading = true;
      const url =
        process.env.VUE_APP_API_BASE_URL +
        "/" +
        _this.$route.params.org_code +
        "/liff/sales/customer-bind-staff";

      try {
        let params = {
          line_id: this.lineData.profile.userId,
          line_access_token: this.lineData.accessToken,
          is_qrcode: this.isQrcode,
          sid: this.staff.id,
          bound_type: this.btype,
        }

        if (this.$route.query.m) {
          params.memo = this.$route.query.m
        }
        let response = await axios.post(url, params);
        if (response.data.status === "success") {
          if (!this.moduleConfig.confirm_page.show_this_page) {
            alert("綁定成功");
            await new Promise(() => liff.closeWindow());
            return;
          }
          this.currentStep = "result";
          return;
        }
        if (response.data.status === "failed") {
          this.$swal.fire({
            type: "error",
            title: "錯誤",
            message: response.response.data.message,
          });
        }
        _this.buttonLoading = false;
      } catch (error) {
        console.error(error);
      }
    },
    async exit() {
      if (
        this.moduleConfig.result_page?.exit_close === false &&
        this.moduleConfig.result_page?.exit_url
      ) {
        window.location.href = this.moduleConfig.result_page?.exit_url;
      } else {
        liff.closeWindow();
        window.close();
      }
    },
    profileClass(style) {
      switch (style) {
        case "title":
          return "list-item-heading truncate text-primary font-weight-bold";
        case "text":
          return "text-muted text-small";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .card {
  width: 250px;
  margin: auto;
}
</style>

<style>
.swal2-container {
  z-index: 10001 !important;
}
</style>
