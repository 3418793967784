<template>
  <div>
    <loading v-if="loading"></loading>
  </div>
</template>

<script>
import liff from "@line/liff";
import LiffChecker from "@/utils/LiffChecker";
import axios from "axios";

export default {
  data() {
    return {
      lineData: null,
      staff: null,
      checker: null,
      loading: true,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      let orgCode = this.$route.params.org_code;
      const checker = new LiffChecker(orgCode);
      this.checker = checker;
      this.lineData = await checker.getLineData();

      let sid = this.$route.query.sid ?? null;
      let employeeCode = this.$route.query.ecode ?? null;

      if (!sid && !employeeCode) {
        alert("網址錯誤，請洽詢業務索取正確網址");
        liff.closeWindow();
        return;
      }

      try {
        let params = {};
        if (sid !== null) {
          params.id = sid;
        }
        if (employeeCode !== null) {
          params.employee_code = employeeCode;
        }

        let response = await axios.get(
          process.env.VUE_APP_API_BASE_URL + `/${orgCode}/liff/sales/get-staff`,
          {
            params
          }
        );

        if (response.data.data) {
          this.staff = response.data.data;
        } else {
          throw false;
        }
      } catch (error) {
        console.error(error);
        let _this = this;
        _this.loading = false;
        _this.$swal.fire({
          type: "error",
          title: "找不到此業務"
        }).then(function () {
          _this.gotoLineOA();
        });
        return;
      }
      // 中租版本的綁定是直接綁定，不需要確認畫面的
      this.accept();
    },
    reject() {
      liff.closeWindow();
    },
    async accept() {
      let _this = this;

      const idToken = await liff.getDecodedIDToken();

      let payload = {
        line_id: this.lineData.profile.userId,
        line_access_token: this.lineData.accessToken,
        sid: this.staff.id,
        bound_type: "QRCode",
      };

      if (idToken?.phone_number) {
        payload.mobile_phone = idToken.phone_number;
      }

      if (idToken?.email) {
        payload.email = idToken.email;
      }

      const url =
        process.env.VUE_APP_API_BASE_URL +
        "/" +
        _this.$route.params.org_code +
        "/liff/bind_staff";

      await axios
        .post(url, payload)
        .then((response) => {
          if (response.data.status === "success") {
            _this.gotoLineOA();
            return;
          }
          if (response.data.status === "failed") {
            alert(response.data.message);
            liff.closeWindow();
          }
        })
        .catch((error) => {
          alert('綁定失敗');
          console.error(error);
        });
    },
    async gotoLineOA() {
      let oaUrl = this.checker.initData.line_oa_url;
      if (oaUrl) {
        window.location.href = oaUrl;
      } else {
        liff.closeWindow();
      }
    }
  },
};
</script>
