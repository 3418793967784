<template>
  <div>
    <loading></loading>
  </div>
</template>

<script>
import LiffChecker from "@/utils/LiffChecker";
import axios from "axios";
import liff from "@line/liff";

export default {
  data() {
    return {
      staff: null,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      const orgCode = this.$route.params.org_code;
      const staffId = this.$route.query.staff_id;

      if (!staffId) {
        alert('此分享名片網址沒有業務資料，請確認網址是否正確');
      }

      const checker = new LiffChecker(orgCode);
      await checker.initLiff();

      // Get Template Data
      try {
        let url = process.env.VUE_APP_API_BASE_URL + `/${orgCode}/liff/sales/share_my_card_template?staff_id=${staffId}`;

        let response = await axios.get(url);

        if (response.data.data) {
          // console.log(response.data.data);
          let message = JSON.parse(response.data.data);
          await this.execShareTargetPicker(message);
        } else {
          throw false
        }
      } catch (e) {
        alert('分享名片失敗');
        console.error(e);
      }
    },
    async execShareTargetPicker(message) {
      if (liff.isApiAvailable('shareTargetPicker')) {
        liff.shareTargetPicker([message], { isMultiple: true }).then(function (res) {
          if (res) {
            console.log('sent');
            liff.closeWindow();
          } else {
            liff.closeWindow();
          }
        }).catch(function (error) {
          console.error(error);
        });
      } else {
        alert('此 LINE 帳號尚未開啟 Share Target Picker 功能');
        console.log('share target picker api not available.');
      }
    }
  }
}
</script>
