<template>
  <div>
    <loading v-if="displayLoading"></loading>

    <template v-if="!staff">
      <div class="page">
        <div class="page__title">啟用帳號</div>
        <div class="page__desc">請填寫您的資料</div>

        <validation-error-alert
          :errors="validationErrors"
          v-if="validationErrors"
        ></validation-error-alert>

        <div class="form">
          <div class="field">
            <label class="field__label">員工編號</label>
            <input
              class="field__input"
              v-model="employeeCode"
              placeholder="請輸入您的員工編號"
            />
          </div>
          <div class="field">
            <label class="field__label">手機號碼</label>
            <input
              class="field__input"
              v-model="mobilePhone"
              placeholder="請輸入您的手機號碼"
            />
          </div>
        </div>
      </div>
      <div class="page__button">
        <SharedButton
          class="s-btn-outline-primary"
          @click="cancel"
          :disabled="buttonLoading"
          >取消</SharedButton
        >
        <SharedButton
          class="s-btn-bg-primary"
          @click="submit"
          :disabled="buttonLoading"
          >送出</SharedButton
        >
      </div>
    </template>

    <template v-else>
      <div class="page">
        <div class="page__title">帳號綁定成功</div>
      </div>
      <div class="page__button">
        <SharedButton class="s-btn-bg-primary" @click="cancel"
          >回官方帳號</SharedButton
        >
      </div>
    </template>
  </div>
</template>

<script>
import LiffChecker from "@/utils/LiffChecker";
import liff from "@line/liff";
import axios from "axios";
import SharedButton from "@/components/Page/Liff/Shared/Button";

export default {
  components: {
    SharedButton
  },
  data() {
    return {
      displayLoading: true,
      lineData: null,
      buttonLoading: false,
      organization: null,
      validationErrors: null,
      employeeCode: null,
      mobilePhone: null,
      staff: null,
    };
  },
  computed: {
    getAvatar() {
      return this.staff.avatar_url ?? "https://placekitten.com/300/300";
    },
  },
  mounted() {
    this.init();
    //this.displayLoading = false;
  },
  methods: {
    async init() {
      const checker = new LiffChecker(this.$route.params.org_code);
      this.lineData = await checker.getLineData();

      let response = await axios.get(
        process.env.VUE_APP_API_BASE_URL +
          "/general/organizations/" +
          this.$route.params.org_code
      );

      if (response.data.data) {
        this.organization = response.data.data;
        this.displayLoading = false;
      } else {
        alert("讀取資料錯誤");
        liff.closeWindow();
      }
    },
    cancel() {
      liff.closeWindow();
    },
    async submit() {
      let _this = this;
      _this.buttonLoading = true;

      const url =
        process.env.VUE_APP_API_BASE_URL +
        "/" +
        _this.$route.params.org_code +
        "/liff/staff_active";

      await axios
        .post(url, {
          line_id: _this.lineData.profile.userId,
          line_access_token: _this.lineData.accessToken,
          employee_code: _this.employeeCode,
          mobile_phone: _this.mobilePhone,
        })
        .then((response) => {
          if (response.data.status === "success") {
            _this.staff = response.data.data.staff;
            return;
          }
          _this.buttonLoading = false;
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 422) {
            this.validationErrors = error.response.data.message;
          }
          if (
            error.response.data.status === "failed" &&
            error.response.data.message
          ) {
            alert(error.response.data.message);
          }
          _this.buttonLoading = false;
        });
    },
  },
};
</script>
