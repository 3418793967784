<template>
  <div>
    <loading v-if="displayLoading"></loading>
    <div class="container mt-3">
      <div class="text-center">
        <h1 class="h5 font-weight-bold mb-0">編輯資料</h1>
      </div>

      <validation-error-alert
        :errors="validationErrors"
        v-if="validationErrors"
      ></validation-error-alert>

      <div class="p-3">
        <b-card :img-src="avatarUrl" img-alt="Image" img-top>

          <div class="d-flex justify-content-between">
            <button
              type="button"
              class="btn btn-block btn-outline-primary mr-3"
              @click="useLineAvatar"
            >
              使用LINE頭像
            </button>
            <label
              for="upload-file"
              class="btn btn-block btn-outline-primary m-0"
            >
              <input
                type="file"
                id="upload-file"
                @change="uploadImage"
                hidden
              />
              <span>上傳照片</span>
            </label>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <div class="form-group">
                <label class="d-block">顯示名稱</label>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="off"
                  v-model="staff.name"
                />
              </div>
            </div>
            <!-- <div class="col-6">
              <div class="form-group">
                <label class="d-block">員工編號</label>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="off"
                  v-model="staff.employee_code"
                  placeholder="員工編號"
                />
              </div>
            </div> -->
          </div>
          <!-- <div class="row mt-2">
            <div class="col-6">
              <div class="form-group">
                <label class="d-block">服務單位</label>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="off"
                  v-model="staff.service_unit"
                  placeholder="服務單位"
                />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label class="d-block">職稱</label>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="off"
                  v-model="staff.job_level"
                  placeholder="職稱"
                />
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <div class="form-group">
                <label class="d-block">手機號碼</label>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="off"
                  v-model="staff.mobile_phone"
                  placeholder="手機號碼"
                />
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <div class="form-group">
                <label class="d-block">E-Mail</label>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="off"
                  v-model="staff.email"
                  placeholder="E-Mail"
                />
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <div class="form-group">
                <label>加我好友網址</label>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="off"
                  v-model="staff.line_contact_url"
                  placeholder="加我好友網址"
                />
              </div>
            </div>
          </div> -->
          <div class="row mt-2">
            <div class="col-6">
              <b-button
                size="lg"
                variant="outline-dark"
                @click="cancel"
                :disabled="buttonLoading"
                block
              >
                取消
              </b-button>
            </div>
            <div class="col-6">
              <b-button
                size="lg"
                variant="primary"
                @click="submit"
                :disabled="buttonLoading"
                block
              >
                送出
              </b-button>
            </div>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import LiffChecker from "@/utils/LiffChecker";
import axios from "axios";
import liff from "@line/liff";

export default {
  data() {
    return {
      validationErrors: null,
      displayLoading: true,
      buttonLoading: false,
      lineData: null,
      staff: {
        name: null,
        employee_code: null,
        service_unit: null,
        job_level: null,
        mobile_phone: null,
        email: null,
        avatar_url: null,
        line_contact_url: null,
      },
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    avatarUrl() {
      return (
        this.staff.avatar_url ??
        "https://via.placeholder.com/500x500.png?text=Empty"
      );
    },
  },
  methods: {
    async init() {
      let _this = this;

      const checker = new LiffChecker(this.$route.params.org_code);
      _this.lineData = await checker.getLineData();

      const url =
        process.env.VUE_APP_API_BASE_URL +
        "/general/staffs/?line_id=" +
        _this.lineData.profile.userId;
      await axios
        .get(url)
        .then((response) => {
          if (response.data.data) {
            this.staff = response.data.data;
          } else {
            throw response.data;
          }
          _this.displayLoading = false;
        })
        .catch((error) => {
          alert("無法正常取得資料，請確認透過正確管道進入此頁面");
          console.error(error);
          liff.closeWindow();
        });
    },
    async submit() {
      let _this = this;
      this.buttonLoading = true;

      let staffData = _this.staff;
      staffData.line_access_token = _this.lineData.accessToken;

      const url =
        process.env.VUE_APP_API_BASE_URL +
        "/" +
        _this.$route.params.org_code +
        "/liff/edit_staff_chailease";

      await axios
        .post(url, staffData)
        .then(async (response) => {
          if (response.data.status === "success") {
            await this.$swal.fire({
              type: "success",
              title: "資料已更新"
            });
            await liff.sendMessages([
                {
                  type: "text",
                  text: "我的名片",
                },
              ])
              .then(() => {
                console.log("message sent");
              })
              .catch((err) => {
                console.log("error", err);
              })
              .finally(() => {
                liff.closeWindow();
              });
            return;
          }
          if (response.data.status === "failed") {
            alert(response.data.message);
            return;
          }
          alert("錯誤");
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.validationErrors = error.response.data.errors;
          } else {
            alert("錯誤");
          }
        })
        .finally(() => {
          _this.buttonLoading = false;
        });
    },
    cancel() {
      liff.closeWindow();
    },
    async uploadImage(event) {
      let _this = this;
      const url = process.env.VUE_APP_API_BASE_URL + "/general/images/upload";

      let data = new FormData();
      data.append("image", event.target.files[0]);

      await axios
        .post(url, data)
        .then((response) => {
          if (response.data.status === "success") {
            _this.staff.avatar_url = response.data.data.image.url;
          } else {
            throw response;
          }
        })
        .catch((error) => {
          console.error(error);
          alert("圖片上傳失敗");
        });
    },
    async useLineAvatar() {
      let _this = this;
      if (!this.lineData.profile.pictureUrl) {
        alert("");
        this.$swal.fire({
          type: "error",
          text: "無法正確讀取到 LINE 照片，請改用上傳圖片"
        });
        return;
      }

      _this.staff.avatar_url = _this.lineData.profile.pictureUrl;
      const url = process.env.VUE_APP_API_BASE_URL + `/${_this.$route.params.org_code}/liff/update_avatar`;

      await axios
        .post(url, {
          avatar_url: _this.staff.avatar_url,
          id: _this.staff.id,
          line_access_token: _this.lineData.accessToken
        })
        .then((response) => {
          if (response.data.status === "success") {
            this.$swal.fire({
              type: "success",
              title: "照片更新成功"
            });
            return;
          }
        })
        .catch((error) => {
          console.error(error);
          alert('錯誤');
        });
    },
  },
};
</script>
