<template>
  <div class="header-wrapper">
    <!--
      Header 圖片版型
      如果有 pageImage 優先顯示
      優先顯示順序：
      pageImage > pageTitle > meta.image > meta.title
     -->
    <div v-if="pageImage || (!pageTitle && metaImage)" class="top-image">
      <img :src="headerImage" />
    </div>

    <!--
      Header 文字版型
     -->
    <div v-else-if="headerTitle" class="s-header">
      <div class="s-header--left">
        <div v-if="headerLogo" class="header__logo">
          <img :src="headerLogo" />
        </div>
      </div>
      <div class="header__title">{{ headerTitle }}</div>
      <div class="s-header--right s-flex" v-if="showMemberCenter">
        <svg
          width="14"
          height="12"
          viewBox="0 0 14 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.2 5.1L7.4 0.3C7.2 0.1 7 0 6.7 0C6.4 0 6.2 0.1 6 0.3L0.2 5.1C0.1 5.2 0 5.4 0 5.5C0 5.6 0 5.7 0.1 5.8C0.2 5.9 0.4 6 0.5 6C0.6 6 0.7 6 0.8 6C0.9 6 0.9 5.9 1 5.8L1.7 5.2V10.4C1.7 10.8 1.8 11.1 2 11.3C2.2 11.5 2.5 11.6 3 11.6H10.5C10.9 11.6 11.2 11.5 11.5 11.3C11.8 11.1 11.8 10.8 11.8 10.4V5.2L12.5 5.8C12.6 5.9 12.6 5.9 12.7 5.9C12.8 5.9 12.8 6 12.9 6C13 6 13.2 6 13.3 5.9C13.4 5.8 13.5 5.7 13.5 5.5C13.4 5.4 13.3 5.2 13.2 5.1ZM10.7 10.2C10.7 10.4 10.7 10.5 10.6 10.6C10.5 10.7 10.4 10.7 10.2 10.7H8.3V7.1C8.3 7 8.3 6.9 8.2 6.8C8.1 6.7 8 6.7 7.9 6.7H5.5C5.4 6.7 5.3 6.7 5.2 6.8C5.1 6.9 5.1 7 5.1 7.1V10.8H3.2C3 10.8 2.9 10.8 2.8 10.7C2.7 10.6 2.7 10.5 2.7 10.3V4.4L6.5 1.2C6.6 1.1 6.6 1.1 6.7 1.1C6.8 1.1 6.9 1.1 6.9 1.2L10.7 4.4V10.2Z"
            fill="white"
          />
        </svg>
        <router-link
          :to="{
            name: 'LiffMemberCenterHome',
            params: { orgCode: $route.params.org_code },
          }"
          >會員中心</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pageTitle: {
      type: String,
    },
    pageImage: {
      type: String,
    },
    meta: {
      type: Object,
    },
    title: {
      type: String,
    },
    showMemberCenter: {
      type: Boolean,
      default: true,
    },
    logo: {
      type: String,
    },
  },

  computed: {
    headerLogo() {
      return this.meta?.header_image || this.logo || "";
    },
    headerTitle() {
      return this.pageTitle || this.meta?.title || this.title || "";
    },
    metaImage() {
      return this.meta?.image || "";
    },
    headerImage() {
      return this.pageImage || this.meta?.image;
    },
  },
};
</script>

<style lang="scss" scoped>
.header__title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
}

.header-wrapper {
  background-color: var(--liff-header-color);
}

.s-header {
  height: 56px;
  color: var(--liff-header-text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.s-header--right {
  position: absolute;
  right: 12px;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  a {
    text-decoration: underline;
    margin-left: 4px;
  }
}

.s-header--left {
  position: absolute;
  left: 12px;
  height: 100%;
}

.header__logo {
  height: 100%;
  img {
    height: 100%;
  }
}

.top-image {
  text-align: center;
  padding: 24px 0 20px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-height: 100px;
    max-width: 300px;
  }
}
</style>
