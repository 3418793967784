export function verifyIdNumber(id) {
    id = id.trim();

    if (id.length != 10) {
        console.log("Fail，長度不正確");
        return false
    }


    let countyCode = id.charCodeAt(0);
    if (countyCode < 65 | countyCode > 90) {
        console.log("Fail，字首英文代號，縣市不正確");
        return false
    }

    let genderCode = id.charCodeAt(1);
    if (genderCode != 49 && genderCode != 50) {
        console.log("Fail，性別代碼不正確");
        return false
    }

    let serialCode = id.slice(2)
    for (let i in serialCode) {
        let c = serialCode.charCodeAt(i);
        if (c < 48 | c > 57) {
            console.log("Fail，數字區出現非數字字元");
            return false
        }
    }

    let conver = "ABCDEFGHJKLMNPQRSTUVXYWZIO"
    let weights = [1, 9, 8, 7, 6, 5, 4, 3, 2, 1, 1]

    id = String(conver.indexOf(id[0]) + 10) + id.slice(1);

    let checkSum = 0
    for (let i = 0; i < id.length; i++) {
        let c = parseInt(id[i])
        let w = weights[i]
        checkSum += c * w
    }

    let verification = checkSum % 10 == 0

    return verification
}
