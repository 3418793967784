<template>
  <div class="s-layout" :style="colorObject">
    <div class="s-container">
      <Header :meta="meta" :showMemberCenter="false" />
      <div class="content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import "bootstrap-vue/dist/bootstrap-vue.css";
import themeColor from "@/mixins/liff/themeColor";
import Header from "@/components/Page/Liff/Shared/Header";

export default {
  components: {
    Header,
  },

  mixins: [themeColor],
  data() {
    return {
      themeConfigPage: "staff_active",
    };
  },
  computed: {
    ...mapGetters({
      themeConfig: "liffGeneral/themeConfig",
      meta: "liffGeneral/meta",
    }),
  },
};
</script>

<style lang="scss">
@import "../../../assets/css/liff/main.css";
@import "../../../../node_modules/font-awesome/css/font-awesome.min.css";

.s-layout {
  color: var(--liff-primary_text_color);
  background-color: var(--liff-layout_bg_color);
}

.s-container {
  min-height: 100vh;
}

.page {
  padding: 16px 12px;
  /* margin-bottom: 12px; */
  background: var(--liff-content_bg_color);
  &__title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 16px;
    text-align: center;
  }
  &__desc {
    font-size: 14px;
    line-height: 22px;
    color: var(--liff-secondary_text_color);
    margin-bottom: 16px;
    text-align: center;
    b {
      color: var(--s-primary);
      font-weight: 600;
    }
  }
  &__button {
    padding: 15px 12px;
    display: flex;
    gap: 12px;
    //background-color: var(--liff-layout_bg_color);
    background-color: white;
    position: sticky;
    bottom: 0;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15) !important;
  }
}

.form {
  padding: 20px 10px;
  &__error-msg {
    color: #fe0000;
    font-size: 12px;
    margin-top: 8px;
  }
}

.field {
  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
  &__label {
    font-weight: 400;
    font-size: 14px;
    color: var(--liff-secondary_text_color);
    margin-bottom: 8px;
    display: block;
  }

  &__desc {
    color: var(--liff-secondary_text_color);
    font-size: 12px;
    line-height: 1;
    margin-top: 4px;
  }

  &__input {
    font-weight: 500;
    font-size: 16px;
    color: var(--liff-primary_text_color);
    border: none;
    border-bottom: 1px solid #e5e5ea;
    background: transparent;
    padding-bottom: 8px;
    width: 100%;
    border: 1px solid #e5e5ea;
    border-radius: 5px;
    padding: 8px 12px;

    &::placeholder {
      color: #b7b7b7;
    }
    &:focus {
      border: 1px solid var(--liff-primary_text_color);
    }
    &.invalid {
      border: 1px solid #fe0000;
    }
    &:read-only {
      background-color: #f0f0f0 !important;
    }
  }

  &--required {
    &:after {
      content: "*";
      position: relative;
      font-family: "PingFang TC";
      color: var(--s-primary);
      margin-left: 2px;
    }
  }

  &__select-group {
    display: flex;
    > * {
      width: 100%;
      &:not(:last-of-type) {
        margin-right: 8px;
      }
    }
    &.invalid {
      border-bottom: 1px solid #fe0000;
    }
  }
}
</style>
