<template>
  <div>
    <loading v-if="displayLoading"></loading>
    <div class="text-center mt-2">
      <h2>填寫顧客資料</h2>
    </div>
    <validation-error-alert :errors="validationErrors" v-if="validationErrors"></validation-error-alert>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label>名字</label>
          <input type="text" v-model="customer.name" class="form-control">
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>手機</label>
          <input type="text" v-model="customer.mobile_phone" class="form-control">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label>生日</label>
          <input type="text" v-model="customer.birthday" class="form-control">
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>E-Mail</label>
          <input type="text" v-model="customer.email" class="form-control">
        </div>
      </div>
    </div>
    <div class="row" v-if="selects.areas">
      <div class="col-12">
        <div class="form-group">
          <label>選擇服務地區</label>
          <select class="form-control" v-model="customer.location_area" @change="areaSelected">
            <option v-for="(area, index) in selects.areas" v-bind:key="index">{{area}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row" v-if="selects.cities">
      <div class="col-12">
        <div class="form-group">
          <label>選擇服務縣市</label>
          <select class="form-control" v-model="customer.location_city" @change="citySelected">
            <option v-for="(city,index) in selects.cities" v-bind:key="index">{{city}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row" v-if="selects.serviceTypes">
      <div class="col-12">
        <div class="form-group">
          <label>選擇服務類型</label>
          <select class="form-control" v-model="customer.service_type">
            <option v-for="(serviceType,index) in selects.serviceTypes" v-bind:key="index">{{serviceType}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-6">
        <b-button class="btn-block" variant="light" @click="cancel" :disabled="buttonLoading">
          取消
        </b-button>
      </div>
      <div class="col-6">
        <b-button class="btn-block" variant="secondary" @click="submit" :disabled="buttonLoading">
          送出
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import liff from "@line/liff"
import LiffChecker from "@/utils/LiffChecker"
import _ from 'lodash'
import axios from "axios";

export default {
  data() {
    return {
      buttonLoading: false,
      displayLoading: true,
      lineData: null,
      branchData: null,
      validationErrors: null,
      customer: {
        name: null,
        mobile_phone: null,
        email: null,
        birthday: null,
        location_area: null,
        location_city: null,
        service_type: null,
      },
      selects: {
        areas: null,
        cities: null,
        serviceTypes: null,
      },
    }
  },
  mounted() {
    this.init()
  },
  watch: {

  },
  methods: {
    async init () {
      let _this = this

      const orgCode = this.$route.params.org_code
      const checker = new LiffChecker(orgCode)

      _this.lineData = await checker.getLineData()

      const url = process.env.VUE_APP_API_BASE_URL + `/${orgCode}/liff/customer_form`

      await axios.get(url).then(response => {
        _this.branchData = response.data.data
        _this.selects.areas = Object.keys(_.groupBy(_this.branchData, 'location_area'))
      }).catch(error => {
        console.log(error)
      });

      _this.displayLoading = false
    },
    areaSelected () {
      const selectedArea = _.filter(this.branchData, {'location_area': this.customer.location_area})
      const cities = _.groupBy(selectedArea, 'location_city')
      this.selects.cities = Object.keys(cities)
    },
    citySelected () {
      const selectedCity = _.filter(this.branchData, {'location_city': this.customer.location_city})
      const serviceTypes = _.groupBy(selectedCity, 'service_type')
      this.selects.serviceTypes = Object.keys(serviceTypes)
    },
    async submit() {
      let _this = this;
      _this.buttonLoading = true;

      const url = process.env.VUE_APP_API_BASE_URL + '/' + _this.$route.params.org_code + '/liff/customer_form'

      await axios.post(url, {
        line_id: _this.lineData.profile.userId,
        line_access_token: _this.lineData.accessToken,
        ..._this.customer,
      }).then(response => {
        if (response.data.status === 'success') {
          liff.sendMessages([
            {
              type: 'text',
              text: '分配業務員'
            }
          ]).finally(() => {
            liff.closeWindow()
          })
        }
        if (response.data.status === 'failed') {
          alert(response.data.message);
        }
        _this.buttonLoading = false;
      }).catch(error => {
        console.log(error);
        if (error.response.status === 422) {
          this.validationErrors = error.response.data.errors;
        }
        _this.buttonLoading = false;
      });
    },
    cancel() {
      liff.closeWindow()
    }
  }
}
</script>
